@font-face {
  font-family: "Suisse Intl";
  src: url("./SuisseIntl.ttf") format("truetype");
}

.TopLeftCorner {
  position: fixed;
  z-index: 100;
  margin: 8px;
  top: 0px;
  max-width: 20px;
}

.TopRightCorner {
  position: fixed;
  z-index: 100;
  margin: 8px;
  right: 0px;
  top: 0px;
  transform: rotate(90deg);
  max-width: 20px;
}

.BottomLeftCorner {
  position: fixed;
  z-index: 100;
  margin: 8px;
  left: 0px;
  bottom: 0px;
  transform: rotate(-90deg);
  max-width: 20px;
}

.BottomRightCorner {
  position: fixed;
  z-index: 100;
  margin: 20px;
  right: 0px;
  bottom: 0px;
  transform: rotate(180deg);
  max-width: 20px;
}

body {
background: #131414;
}

.App {
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
}

.AppInner {
  max-width: 720px;
  margin: auto;
}

.Nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

video {
  width: 100%;
  border-radius: 24px;
  cursor: grab;
}

p {
font-family: 'Suisse Intl';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 120%;
letter-spacing: -1px;
color: #DEE7E1;
transition: 0.2s;
cursor: grab;
}

p:hover {
  background-color: rgba(141, 140, 140, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 4px;
}

.Nav p {
  color: #DEE7E1;
}

button {
padding: 10px 16px;
max-height: 48px;
border: 2px solid #DEE7E1;
border-radius: 100px;
font-family: 'Suisse Intl';
font-style: normal;
font-weight: 400;
font-size: 20px;
letter-spacing: -1px;
color: #DEE7E1;
background: #66726a;
cursor: pointer;
transition: 0.2s;
width: fit-content;
color: #131414;
backgorund: linear-gradient(19deg,#cbaaff,#4472ff,#3864ffbd);
position: relative;
z-index: 0;
box-shadow: 0 0 4px 0 rgba(255, 255, 255, 0.01);
overflow: hidden;
border: none;
}

button::before {
    content: "";
    background: #DEE7E1;
    filter: blur(4px);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    transition: 0.2s;
}

button:hover {
  transform: scale(1.1);
}

button:hover::before {
  /* background: #DEE7E1;
  color: #fff; */
  filter: blur(6px);
}


.ButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ModalStyle {
  background: #131414;
  width: calc(100vw - 8rem);
  max-width: 34.625rem;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 96px 128px rgb(0 0 0 / 8%);
  animation-duration: 0.2s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
}

@keyframes animate-pop {
  0% {
    margin-top: 10px;
  }

  100% {
    margin-top: 0px;
  }
}

.SliderImage {
  width: inherit;
  border-radius: 32px;
}

.alice-carousel__wrapper {
  border-radius: 32px;
}

.alice-carousel__dots-item {
  background-color: #dadce0 !important;
}

.alice-carousel__dots-item.__active {
  background-color: #DEE7E1 !important;
}

.move {}

.VideoDelta {
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 10px;
  padding: 5px 16px;
  border-radius: 100px;
  font-family: 'Suisse Intl';
  font-style: normal;
  font-size: 20px;
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  mix-blend-mode: exclusion;
}

.ImageTag {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 10px;
  padding: 5px 16px;
  border-radius: 100px;
  font-family: 'Suisse Intl';
  font-style: normal;
  font-size: 20px;
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  mix-blend-mode: exclusion;
}

.Toucher {
  bottom: 64px;
  background: #DEE7E1;
  z-index: 1000;
}
